import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import Layout from '../components/layout';
import TrainingVideosSectionUpdate from '../components/Sections/TrainingVideosSectionUpdate';
import {
  withAuthorization,
} from '../components/Session';
import HeadData from "../data/HeadData"
import BackButton from '../components/Common/BackButton';

const TrainingVideosPageBase = () => (
  <DashboardWrapper pageName='Training Videos'>
    <div className="relative pt-12 pb-32 md:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <TrainingVideosSectionUpdate />

        <BackButton buttonLink='/dashboard' />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const TrainingVideosPage = compose(
  withAuthorization(condition),
)(TrainingVideosPageBase);

const TrainingVideos = () => (
  <Layout>
    <HeadData title='Training Videos' />
    <TrainingVideosPage />
  </Layout>
)

export default TrainingVideos
